import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import rehypeRaw from "rehype-raw"
import ReactMarkdown from "react-markdown"

const Privacy = ({ data, pageContext }) => {

    const page = data.page.edges[0].node

    return (
        <Layout pageContext={pageContext}>
            <Seo
                lang={pageContext.langKey}
                title={page.seo.title}
                description={page.seo.meta_description}
                translates={pageContext.translates}
                image={page.seo?.image_2?.localFile?.publicURL}
            />

            <main className="main">
                <div className="main__section main__section--20-80">
                    <div className="container">
                        <div className="hidden-lg">
                            <div className="container__index">
                                <a href={`#responsible`}>{page.responsible_title}</a>
                                <a href={`#delegate`}>{page.delegate_title}</a>
                                <a href={`#general_info`}>{page.general_info_title}</a>
                                <a href={`#supply`}>{page.supply_title}</a>
                                <a href={`#cookies`}>{page.cookies_title}</a>
                                <a href={`#newsletter`}>{page.newsletter_title}</a>
                                <a href={`#contact`}>{page.contact_title}</a>
                                <a href={`#form`}>{page.form_title}</a>
                                <a href={`#appearances`}>{page.form_title}</a>
                                <a href={`#presences`}>{page.presences_title}</a>
                                <a href={`#hosting`}>{page.hosting_title}</a>
                                <a href={`#geotargeting`}>{page.geotargeting_title}</a>
                                <a href={`#plugins`}>{page.plugins_title}</a>
                            </div>
                        </div>
                        <div className="container__text">
                            <h1 className="merriweather">{page.title}</h1>
                            <div className="container__text__text">
                                <h3 id="responsible">{page.responsible_title}</h3>
                                <ReactMarkdown children={page.responsible_description} rehypePlugins={[rehypeRaw]} />
                                <div className="list-table">
                                    {page.responsible_items.map((item, index) => (
                                        <div key={index} className="list-table__item">
                                            <h6>{item.title}</h6>
                                            <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} />
                                        </div>
                                    ))}
                                </div>

                                <h3 id="delegate">{page.delegate_title}</h3>
                                <ReactMarkdown children={page.delegate_description} rehypePlugins={[rehypeRaw]} />
                                <div className="list-table">
                                    {page.delegate_items.map((item, index) => (
                                        <div key={index} className="list-table__item">
                                            <h6>{item.title}</h6>
                                            <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} />
                                        </div>
                                    ))}
                                </div>

                                <h3 id="general_info">{page.general_info_title}</h3>
                                <ReactMarkdown children={page.general_info_description} rehypePlugins={[rehypeRaw]} />

                                <h3 id="supply">{page.supply_title}</h3>
                                <ReactMarkdown children={page.supply_description} rehypePlugins={[rehypeRaw]} />

                                <h3 id="cookies">{page.cookies_title}</h3>
                                <ReactMarkdown children={page.cookies_description} rehypePlugins={[rehypeRaw]} />
                                <div className="list-table list-table--fixed">
                                    <div className="list-table__item">
                                        <h6>Cookies</h6>
                                        <h6>Finalidad</h6>
                                        <h6>Titular</h6>
                                        <h6>Duración</h6>
                                    </div>
                                    {page.cookies_items.map((item, index) => (
                                        <div key={index} className="list-table__item">
                                            <p>{item.name}</p>
                                            <p>{item.purpose}</p>
                                            <p>{item.titular}</p>
                                            <p>{item.duration}</p>
                                        </div>
                                    ))}
                                </div>

                                <h3 id="newsletter">{page.newsletter_title}</h3>
                                <ReactMarkdown children={page.newsletter_description} rehypePlugins={[rehypeRaw]} />

                                <h3 id="contact">{page.contact_title}</h3>
                                <ReactMarkdown children={page.contact_description} rehypePlugins={[rehypeRaw]} />

                                <h3 id="form">{page.form_title}</h3>
                                <ReactMarkdown children={page.form_description} rehypePlugins={[rehypeRaw]} />

                                <h3 id="appearances">{page.appearances_title}</h3>
                                <ReactMarkdown children={page.appearances_description} rehypePlugins={[rehypeRaw]} />

                                <h3 id="presences">{page.presences_title}</h3>
                                <ReactMarkdown children={page.presences_description} rehypePlugins={[rehypeRaw]} />

                                <h3 id="hosting">{page.hosting_title}</h3>
                                <ReactMarkdown children={page.hosting_description} rehypePlugins={[rehypeRaw]} />

                                <h3 id="geotargeting">{page.geotargeting_title}</h3>
                                <ReactMarkdown children={page.geotargeting_description} rehypePlugins={[rehypeRaw]} />

                                <h3 id="plugins">{page.plugins_title}</h3>
                                <ReactMarkdown children={page.plugins_description} rehypePlugins={[rehypeRaw]} />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </Layout>
    )
}

export default Privacy

export const privacyPageQuery = graphql
        `query($langKey: String) {
        page: allStrapiLegalPrivacidad (
            filter: { locale: { eq: $langKey } }
        ) {
            edges {
                node {
                    id,
                    seo {
                        title,
                        meta_description
                        image_2 {
                            localFile {
                                publicURL

                            }
                        }
                    }
                    title
                    responsible_title
                    responsible_description
                    responsible_items {
                        order
                        title
                        description
                    }
                    delegate_title
                    delegate_description
                    delegate_items {
                        order
                        title
                        description
                    }
                    general_info_title
                    general_info_description
                    supply_title
                    supply_description
                    cookies_title
                    cookies_description
                    cookies_items {
                        name
                        purpose
                        titular
                        duration
                    }
                    newsletter_title
                    newsletter_description
                    contact_title
                    contact_description
                    form_title
                    form_description
                    appearances_title
                    appearances_description
                    presences_title
                    presences_description
                    hosting_title
                    hosting_description
                    geotargeting_title
                    geotargeting_description
                    plugins_title
                    plugins_description
                }
            }
        }
    }`
